.modal {
	& .modal-content {
		& > * {
			position: relative;
		}
	}
	& .modal-dialog{
		&.modal-full {
			max-width: 100%;
			width: 100%;
			
			& .modal-content {
				border-radius: 0;
			}
		}
	}
	&.modal-cover {
		& .modal-dialog {
			min-height: 100%;
			margin-top: 0;
			margin-bottom: 0;
			display: flex;
			align-items: center;
		}
		& .modal-content,
		& .modal-header,
		& .modal-footer {
			background: none;
			border: none;
		}
	}
}

@if $enable-dark-mode {
  @include color-mode(dark, true) {
  	.modal-backdrop {
  		--#{$prefix}backdrop-bg: #{$modal-backdrop-bg-dark};
  	}
  }
}

