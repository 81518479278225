.loader {
  border: 4px solid #1494d3;
  border-top: 4px solid #1f2937;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate((0deg));
  }
  100% {
    transform: rotate(360deg);
  }
}
